import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import moment from 'moment-timezone';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadCrumbs';
import { useData } from 'src/context/Provider';
import { currencyConverter } from 'src/helpers/currency';

const DonationSuccess = () => {
  const {
    state: { user, brand }
  }: any = useData();

  const toastConfig = React.useContext(CustomToastContext);
  const [ordersData, setOrdersData] = React.useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance()
      .get(`/donation/${id}`)
      .then(({ data: { data } }) => {
        setOrdersData(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <div className="container">
      <Box my={2}>
        {user ? (
          <CustomBreadcrumbs
            routes={[
              { title: 'Donation-Pay', path: '/donation/orders' },
              { title: ordersData ? ordersData.orderNumber : '', path: '' }
            ]}
          />
        ) : (
          <CustomBreadcrumbs routes={[{ title: ordersData ? ordersData.orderNumber : '', path: '' }]} />
        )}
      </Box>
      <Box pt={3}>
        <Box border={1} borderColor="grey.300" mb={2} p={2}>
          <span>Detail : {ordersData?.orderNumber}</span>
          <br />
          <br />
          <span>Contribute To : {ordersData?.contributeTo?.contributeType}</span>
          <br />
          <br />
          <span>Name : {ordersData?.name}</span>
          <br />
          <br />
          <span>Email : {ordersData?.email}</span>
          <br />
          <br />
          <span>Phone : {ordersData?.phone}</span>
          <br />
          <br />
          {ordersData?.jatha ? (
            <>
              <span>Jatha : {ordersData?.jatha}</span>
              <br />
              <br />
            </>
          ) : null}
          <span>Amount : {currencyConverter(ordersData?.amount, brand?.currency || 'USD')}</span>
          <br />
          <br />
          <span>Date : {moment(ordersData?.createDate).format('dddd, Do MMM h:mm A')}</span>
          <br />
          <br />
        </Box>
      </Box>
    </div>
  );
};

export default DonationSuccess;
